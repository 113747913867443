import {   
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAIL,
    CLEAR_INPUT_VALUES,
    GET_VALIDATION_FOCUS,
    CLEAR_VALIDATION_STATUS,
    RESET_STATE_VALUE,
    SET_LOGIN_ID,
    SET_LOGIN_BUTTON_CLICKED,
    SET_FOOTER_CONTENT,
    GET_FOOTER_CONTENT
} from '../../constants/logonActionTypes';
import { LoginValidationStatus } from '../../constants/logonValidation';

const initialstate = {
    loginId: '',
    validationStatus:'',
    loginButtonClicked: false,
    isValidationFocus: false,
    isLoading: false,
    isLoadingTokenDialogue: false,
    correlationId: null,
    isContentLoading: true,
    data: {
        authenticationResult: null,
        logonStatus: null,
        redirectionUrl: null
    },
    error:{
        message:null,
        response: {
            data:null,
            status:null
        },
        target:null
    },
    CMS: {}
};

const loginUser = (state = initialstate, action) => {
    switch(action.type) {
        case SET_LOGIN_ID:
            return {
                ...state,
                loginId: action.value
            }
        case SET_LOGIN_BUTTON_CLICKED:
                return {
                    ...state,
                    loginButtonClicked: action.value
                }
        case GET_VALIDATION_FOCUS:            
            return {
                ...state,
                isValidationFocus: action.value
            }    
        case LOGIN_USER:
            let isLoginButtonClicked = state.loginButtonClicked;
            if(isLoginButtonClicked) {
                return {...state,
                isLoading: true,
                isLoadingTokenDialogue: false,
                ...action.payload }
            } 
            else{
                return {...state,
                isLoading: false,
                isLoadingTokenDialogue: true,
                ...action.payload }
            }
           
        case LOGIN_USER_SUCCESS:
            if(action.payload.data.error && action.payload.data.error.target){
                switch (action.payload.data.error.target) {
                    case LoginValidationStatus.LOGIN_ID_MISSING:
                    case LoginValidationStatus.PASSWORD_MISSING:
                    case LoginValidationStatus.PAGE_TIMEOUT_EXPIRED:
                        return mapActionResponsetoState(state, action, action.payload.data.error.target, true, false);
                    default:
                }
            }
            if(action.payload.data.data){
                if(action.payload.data.data.logonStatus === LoginValidationStatus.INVALID_CREDENTIALS){
                    document.getElementById('loginId').focus();
                    return {
                        ...state,                      
                        validationStatus: LoginValidationStatus.INVALID_CREDENTIALS,
                        isValidationFocus: true,
                        isLoading: false,
                        isLoadingTokenDialogue: false,
                        ...action.payload.data
                    }
                 
                }
                else {
                    if (action.payload.data.data.logonStatus === LoginValidationStatus.AUTHENTICATED ||
                        action.payload.data.data.logonStatus === LoginValidationStatus.CHANGE_TEMPORARY_PASSWORD ||
                        action.payload.data.data.logonStatus === LoginValidationStatus.MERGED_USER) {
                            window.location.href = action.payload.data.data.redirectUrl;
                    }
                }
            }
            return {...state,
            isLoading:false,
            isLoadingTokenDialogue: false,
            validationStatus: '',
            ...action.payload.data }
        case LOGIN_USER_FAIL:
            return {...state,
            isLoading: false,
            isLoadingTokenDialogue: false,
            data: {
                authenticationResult: null,
                logonStatus: null,
                redirectionUrl: null
            },
            error: { 
                message: action.payload.error, 
                response : action.payload.error.response, 
                target: LoginValidationStatus.EXCEPTION 
            }
         }
         case CLEAR_INPUT_VALUES:
             return {
                 ...state,
                 isValidationFocus: true,
                 error : {
                     target: null
                 }
        }
        case CLEAR_VALIDATION_STATUS:
            return {
                ...state,
                validationStatus: ''
        }
        case RESET_STATE_VALUE:{
            return {
                ...state,
                data: {
                    logonStatus : null
                },
                isValidationFocus:false,
                validationStatus:'',
                loginId : state.loginId,
                error : {
                    target: null
                }
            };
        }
        case GET_FOOTER_CONTENT: {
            return {
                ...state,
                isContentLoading: true
            };
        }
        case SET_FOOTER_CONTENT: {
            return {
                ...state,
                CMS: action.value.data.data,
                isContentLoading: false
            };
        }
        default:            
            return state;
    }
}

const mapActionResponsetoState = (state, action, validationStatus, isValidationFocus, isLoading, isLoadingTokenDialogue) =>
 {
    document.getElementById('loginId').focus();
    return {
        ...state,
        validationStatus: validationStatus,
        isValidationFocus: isValidationFocus,
        isLoading: isLoading,
        isLoadingTokenDialogue: isLoadingTokenDialogue,
        correlationId: action.payload.data.correlationId,
        error: { target: action.payload.data.error.target },
        ...action.payload.data
    }
}

export default loginUser;