import React, {useEffect,useState} from 'react';
import { useDispatch,useSelector } from 'react-redux';
import { getFooterContent } from '../../redux/actions/logonActions';
import {ButtonActionEmphasis} from '@honeycomb-npm/honeycomb-react';
import {HeaderComponent} from '../Header/header';
import ContentPanel from '../Logon/contentPanel';
import {FooterComponent} from '../Footer/footer';
import { loginUrl } from '../../constants/Urls';
import Constants from '../../constants/constants';
import './logoffuser.scss';
import {PageLoad} from '../../scripts/adobeDataLayer.js';
import { PAGE } from '../../constants/common';
import trackLinkClicks from '../../scripts/adobeCJAHandler';
import { BioCatchEnvUrl, PROD_URL_DOMAIN, STAG_URL } from './../../constants/bioCatch';

const cancelOption = e => {
  e.preventDefault();
  window.location.href = loginUrl;
}

const LogoffUser = (props) => {

  const dispatch = useDispatch();
  const isCJAEnabled = useSelector(store => store.adobeCJA.isAdobeCjaEnabled);
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [scriptInjected, setScriptInjected] = useState(false);  
  const [isCjaTriggered, setCjaTriggered] = useState(false);

  useEffect(() => {
    if (isCJAEnabled) {
      if (!scriptInjected) {
        setScriptInjected(true);
        bioCatchScriptInjection(setScriptLoaded);
      }
      if (scriptLoaded) {       
        if (isCJAEnabled && !isCjaTriggered) {
          setCjaTriggered(true);
          PageLoad("pageview", PAGE.SIGNOUT);
        }
      }
    }
  }, [isCJAEnabled, scriptLoaded]);

  useEffect(() => {
    if (isCJAEnabled) {
      const cleanup = trackLinkClicks();
      return () => {
        cleanup();
      };
    }
  }, [isCJAEnabled]);

  useEffect(() => {    
    document.title = Constants.logOffPanel.LOG_OUT_TITLE;
    dispatch(getFooterContent());
  }, [dispatch])

  return(
      <div className="signed-out">
      <div className="background-image"></div>
      <HeaderComponent displayForPage={false}/>
      <div className="container">
      <div className = "col-xs-6 col-lg-12">
        <section className="my-4 log-off-user">
            <div className="pl-1 pb-1">
              <h1 className="mt-8 mb-4 unable-to-logon">{Constants.logOffPanel.LOG_OUT_COMMBIZ}</h1>
              <p className="thanks-text mb-6">{Constants.logOffPanel.LOG_OUT_CONTENT}</p>
                <ButtonActionEmphasis
                  id="splashCancelButton"
                  className="mb-8"
                  onClick={(event) => { cancelOption(event) }}
                >
                  {Constants.logOffPanel.LOG_OUT_PAGE_LOGON_TEXT}
                </ButtonActionEmphasis>
          </div>
        </section>
      </div>
    </div>
    <ContentPanel />
    <FooterComponent displayForPage={false}/>
  </div>
  )
};

export default LogoffUser;

export const bioCatchScriptInjection = (setScriptLoaded) => {
  let bioCatchUrl = BioCatchEnvUrl['Non-Prod'];
  if (isStag()) {
      bioCatchUrl = BioCatchEnvUrl['Staging']
  }
  else if (isProd()) {
      bioCatchUrl = BioCatchEnvUrl['Prod'];
  }
  const loadScript = document.createElement('script');
  loadScript.setAttribute("src", bioCatchUrl);

  loadScript.onload = () => {
      setScriptLoaded(true);
  };

  document.head.appendChild(loadScript);
}

export const isProd = () => {
  return window.location.hostname.includes(PROD_URL_DOMAIN);
}

export const isStag = () => {
  return window.location.hostname.includes(STAG_URL);
}
