import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAIL,
    CLEAR_INPUT_VALUES,
    GET_VALIDATION_FOCUS,
    CLEAR_VALIDATION_STATUS,
    RESET_STATE_VALUE,
    SET_LOGIN_ID,
    SET_LOGIN_BUTTON_CLICKED,
    SET_FOOTER_CONTENT,
    GET_FOOTER_CONTENT } from '../../constants/logonActionTypes';

export const getValidationFocus = (value) => {
    return {        
        type: GET_VALIDATION_FOCUS,
        value
    }
}

export const clearInputValues = () => {
    return {
        type: CLEAR_INPUT_VALUES
    }
}

export const loginUser = (user) => {
    return {
        type: LOGIN_USER,
        payload: user
    }
};

export const loginUserSuccess = (userresponse) => {
    return {
        type: LOGIN_USER_SUCCESS,
        payload: userresponse
    }
};

export const loginUserFailed = (message) => {
    return {
        type: LOGIN_USER_FAIL,
        payload: message.response.data
    }
};

export const clearValidationStatus = () => {
    return {
        type: CLEAR_VALIDATION_STATUS
    }
}
export const resetStateValue = () => {	
    return {	
        type: RESET_STATE_VALUE	
    }
}

export const setLoginId = (value) => {
    return {        
        type: SET_LOGIN_ID,
        value
    }
}

export const setLoginButtonClicked = (value) => {
    return {        
        type: SET_LOGIN_BUTTON_CLICKED,
        value
    }
}

export const getFooterContent = () => ({ type: GET_FOOTER_CONTENT });

export const setFooterContent = (value) => {
    return {
        type: SET_FOOTER_CONTENT,
        value
    }
};
